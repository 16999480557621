function messageFromJavascript(message){
  let element =  document.querySelector('.messages-for-javascript .message');
  let parent = document.querySelector('.messages-for-javascript');
  element.innerHTML = message;
  parent.classList.remove("hidden");
  setTimeout(()=> {
    parent.classList.add("hidden");
  }, 6000)
}


window.addEventListener("trix-file-accept", function(event) {
  const maxFileSize = 4 // 4MB 
  const fileSize = event.file.size / 1024 / 1024;
  if (fileSize > maxFileSize) {
    event.preventDefault()
    messageFromJavascript("El peso máximo permitido por imagen es de " + maxFileSize + "MB.")
  }
  const acceptedTypes = [
    'image/jpeg', 
    'image/webp', 
    'image/png', 
    'image/jpg', 
    'image/gif', 
    'application/pdf', 
    'application/vnd.ms-excel', 
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/wps-office.xlsx'
  ]
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    messageFromJavascript("Los formatos permitidos son: jpeg, jpg, png y gif.")
  }
})


function setContent( attachment, data ) {
  let file = attachment.file;
  let attributes ={
      previewable: false,
      url: data.url,
      href: data.url
  };

  if (!data.mime ){ 
      data.mime = "application/file";
  }
 
  if ( data.mime.includes("image") ) { // image
      attributes["content"] = `
          <span class="trix-preview-image" data-url="${data.url}"  data-name="${file.name}" >
              <img src="${data.url}" /> 
          <span>
      `;

  } else if ( data.mime.includes("video") ) { // video
      attributes["content"] = `
          <span class="trix-preview-video"  data-url="${data.url}"  data-name="${file.name}" >
              <video width="100%" height="auto" controls>
                  <source src="${data.url}" type="${data.mime}">
              </video>
          <span>
      `;

  } else {  // other
      attributes["content"] = `
          <span class="trix-preview-file" data-url="${data.url}"  data-name="${file.name}" >
              ${file.name}
          <span>
      `;
  }

  attachment.setAttributes(attributes);
}  