// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "packs/init-alpine-data"
require("trix")
require("@rails/actiontext")
import 'alpine-turbo-drive-adapter' 
import 'alpinejs'
import "chartkick/chart.js"
import "../trix-editor-overrides"
import "stylesheets/application"
import 'packs/survey'
import 'packs/selectize.min'
import '../../assets/javascripts/serviceworker-companion.js';	
import Cropper from 'cropperjs';
window.Cropper = Cropper;



Rails.start()
Turbolinks.start()
ActiveStorage.start()




import $ from 'jquery'
import 'jquery-ui/ui/widgets/sortable'


window.jQuery = $;
window.$ = $; // lazy fix if you want to use jQuery in your inline scripts.
import fancybox from '@fancyapps/fancybox';

$(document).on('ready turbolinks:load ajax:success', function() {
  $('.sortable').sortable({
    handle: ".move",
    touchAction: 'auto',
    update: function(e, ui) {
      Rails.ajax({
        url: $(this).data('url'),
        type: 'PATCH',
        data: $(this).sortable('serialize')
      });
    }
  });
  
  $('figure.attachment--preview').attr('data-fancybox', true);

  //Para que los links de trix se abran en otro tab
  const links = document.querySelectorAll('.trix-content a');
  links.forEach(link => {
    link.addEventListener('click', (event) => {
      event.preventDefault();
      const t = event.target;
      window.open(t.href, '_blank');
    });
  });


  $('.tags-selectize').selectize({
    delimiter: ",",
    persist: false,
    create: function (input) {
      return {
        value: input,
        text: input,
      };
    },
    render: {
      option_create: function(data, escape) {
        var addString = 'Crear etiqueta';
        return '<div class="create ml-2">' + addString + ' <strong>' + escape(data.input) + '</strong>&hellip;</div>';
      }
    }
  });

  $('.company-selectize').selectize({
    delimiter: ",",
    persist: false,
    create: function (input) {
      //console.log(input)
      return {
        value: input,
        text: input,
      };
    },
    render: {
      option_create: function(data, escape) {
        var addString = 'Crear compañía';
        return '<div class="create ml-2">' + addString + ' <strong>' + escape(data.input) + '</strong>&hellip;</div>';
      }
    }
  });

  $('.simple-selectize').selectize();
});








